import { template as template_c4ca0cb203dd4a19b28c105200dde036 } from "@ember/template-compiler";
const FKLabel = template_c4ca0cb203dd4a19b28c105200dde036(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
