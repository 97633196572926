import { template as template_906f44e2068b4e96b9e75137d34dcb7d } from "@ember/template-compiler";
const WelcomeHeader = template_906f44e2068b4e96b9e75137d34dcb7d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
