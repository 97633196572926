import { template as template_00b525c3ae68499e9867a4b97422b4ce } from "@ember/template-compiler";
const FKControlMenuContainer = template_00b525c3ae68499e9867a4b97422b4ce(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
