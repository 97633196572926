import { template as template_4a58d9cf20f1444c829dcf4cdc57907a } from "@ember/template-compiler";
const FKText = template_4a58d9cf20f1444c829dcf4cdc57907a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
